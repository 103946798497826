import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="col-xs-12">
          {" "}
          <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
        </div>
        <div className="col-xs-12">
          <div className="about-text">
            <h2>Rent in Germany .Info</h2>
            <div>
              <h3>
                <b>Save your time.</b><br/> 
                Get informed first; Search home after.
              </h3>
              <h4 style={{"color":"rgb(63,81,181)", "line-height": "30px"}}>
                How much is <u>1h of your time</u> worth? What about <u>5h?</u> or <u>10h?</u><br/>
                What does it mean to you to <b>find a home</b> <u>1 week earlier</u>? or maybe 1 month?<br/>
                Save time and increase your chances with this affordable Info-PDF.
              </h4>

              <p><em><font color="#6e6e6e">After 2 months searching a home in Berlin, I finally got an offer.</font></em></p>
              <p><em><font color="#6e6e6e">Some friends tell me I had luck: it can take much longer than that. <u>As well as in many other crowded cities in Germany.</u></font></em></p>
              <p><em><font color="#6e6e6e">Nevertheless, it was not luck. I spent several hours of research, asked for feedback to each real state manager / flat owner at each of viewings, gradually optimised my applications, and the results...</font></em></p>
              <p><em><font color="#6e6e6e">I went </font><b>from 1 to 5 flat viewings per week</b><font color="#5a5a5a">,</font><font color="#6e6e6e"> i.e., increased in a 5X factor my chances of getting an offer.</font></em></p>
              <p><em><font color="#6e6e6e">If I had opmized everything since the beginning...</font></em></p>
              <p><em><font color="#6e6e6e">Not only that. At some point I was invited to view <b>THE PERFECT FLAT</b> for me. Loved it. Told the owner that I REALLY wanted it. But...</font></em></p>
              <p><em><font color="#6e6e6e"><b>I made a terrible mistake</b>, and the owner ended up offering it to another applicant - <b>setting me back to the search for another 3 weeks!</b> Until I finally figured everything out and got an offer.</font></em></p>
              <p><em><font color="#6e6e6e">If only I knew the best practices in viewings since my first ones... I might have gotten a home way earlier.</font></em></p>
              <p><em><font color="#6e6e6e">Upside: I documented everything and now you can </font><b>SAVE several hours of search</b> <font color="#6e6e6e">and potentially get an offer several weeks earlier using the info in this very affordable PDF.{"\n"}</font></em></p>
              <p><em><font color="#6e6e6e">Of course, it all depends on your specific needs (house details) and resources (budget for the rent). For this and other reasons, <u>please read carrefully the disclaimer below</u>.</font></em></p>
              <p><em><font color="#6e6e6e">Still, practical experience shows the actions listed in our PDF can <b>substantially increase your response-rate in applications</b> (i.e. number of viewings) and <b>prevent you from making mistakes</b> that might lead to missing rental offers..</font></em></p>
              <p style={{"text-align":"center"}}>-</p>
              <p style={{"text-align":"center", "color":"rgb(63,81,181)"}}><b><u>DISCLAIMER</u></b>: This document has INFORMATIONAL purpose ONLY. No guarantee (neither of number of viewings, or of rental offers, or of any kind) is provided. We do NOT provide any legal advice, nor take any liability for any action taken by the customer/reader.</p>
            </div>
          </div>
          <div>
            <div className="centered-content">
              <a className="tentaryButton" href="https://shop.rentingermany.info/p/6iK536/checkout">
                Get PDF now
              </a>
            </div>
            <div className="centered-content">
              * You will be redirected to our 3rd-party payment service provider -  
              <a href="https://tentary.com/en/imprint" target="_blank" rel="noreferrer">Tentary</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
